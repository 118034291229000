<template>
  <v-container>
    <!-- <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>-->
    <v-row>
      <v-col class="display-1 mb-12">My Profile</v-col>
      <v-col class="text-left text-sm-right">
        <!-- <v-btn
          text
          class="blue--text"
          @click="changePasswordModal = !changePasswordModal"
        >Change password</v-btn>-->
        <v-dialog v-model="changePasswordModal" max-width="400px" persistent>
          <template v-slot:activator="{ on }">
            <!-- <v-btn color="primary" v-on="on">Open Dialog</v-btn> -->
            <v-btn text class="blue--text" v-on="on" @click="resetChangePasswordForm()">Change My Password</v-btn>
          </template>
          <v-form>
            <v-card>
              <v-card-title class="headline mb-8">Change My Password</v-card-title>
              <!-- <v-divider class="mx-4"></v-divider> -->
              <v-card-text>
                <v-text-field label="Current Password" v-model="password.current" autofocus=""></v-text-field>
                <v-text-field label="New Password" v-model="password.new"></v-text-field>
                <v-text-field label="Confirm New Password" v-model="password.newConfirm"></v-text-field>
              </v-card-text>
              <!-- <v-divider class="mx-4"></v-divider> -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="changePasswordModal = false">Close</v-btn>
                <v-btn color="success" text @click="changePasswordModal = false">Update</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="formIsValid">
      <v-card outlined>
        <v-card-title>Contact Info</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                label="First Name"
                name="firstName"
                required
                dense
                disabled
                autofocus
                :counter="50"
                :rules="nameRules"
                v-model="firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                label="Last Name"
                name="lastName"
                required
                dense
                disabled
                autofocus
                :counter="50"
                :rules="nameRules"
                v-model="lastName"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field label="E-Mail" name="email" required disabled :rules="emailRules" v-model="email"></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                label="Phone Number"
                name="phone"
                required
                disabled
                v-mask="mask"
                :rules="phoneNumberRules"
                v-model="phoneNumber"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- <div>{{fullName}}</div>
    <div>{{email}}</div>
    <div>{{phoneNumber}}</div>
    <div>{{mailingAddressOne}}</div>
    <div>{{mailingAddressTwo}}</div>
    <div>{{mailingCity}}</div>
    <div>{{mailingState}}</div>
    <div>{{mailingZipCode}}</div>
    <div>{{billingAddressOne}}</div>
    <div>{{billingAddressTwo}}</div>
    <div>{{billingCity}}</div>
    <div>{{billingState}}</div>
    <div>{{billingZipCode}}</div>
    <div>{{paymentType}}</div>
    <div>{{taxableAmount}}</div>
    <div>{{deliveryDate}}</div>
    <div>{{fullName}}</div>-->

    <div style="padding: 40px 0;" class="text-right">
      <!-- <v-btn x-large color="success" @click="validate">Save</v-btn> -->
      <v-btn x-large color class="" text @click="goBack()">Back</v-btn>
    </div>

    <hr />
    <h2 style="margin-top:40px;">Items needed on this page</h2>
    <div style="font-size: 20px;">- Need api endpoint to get user profile info (currently using token)</div>
    <div style="font-size: 20px;">- Need api endpoint to update user's password</div>

    <v-snackbar v-model="snackbar" :multi-line="multiLine" top right :timeout="10000" :color="snackBarColor">
      <h3>{{ snackMsgText }}</h3>
      <!-- <v-btn color="red" text @click="snackbar = false">Close</v-btn> -->
      <v-btn class="mx-2" icon small @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapGetters } from 'vuex';
// import ProgressBar from '../../../components/transfer/ProgressBar.vue';

export default {
  directives: {
    mask,
  },
  components: {},
  created() {
    this.$store.dispatch('app/profile_getUserData');

    // // console.log("step-1 created, ", this.successMsg);
    // if (this.successMsg) {
    //   this.snackMsgText = this.successMsg;
    //   this.snackBarColor = "success";
    //   this.snackbar = true;
    // }
  },
  data: () => ({
    mask: '(###) ###-####',
    // firstName: null,
    // lastName: null,
    formIsValid: true,
    multiLine: true,
    snackbar: false,
    snackMsgText: '',
    snackBarColor: '',
    changePasswordModal: false,
    password: {
      current: null,
      new: null,
      confirmNew: null,
    },
    nameRules: [
      v => !!v || 'Name is required',
      // (v) => /^[a-zA-Z]+$/.test(v) || 'Only letters are allowed',
      v => /^[a-zA-Z][a-zA-Z\s]*$/.test(v) || 'Only letters and spaces are allowed',
      v => (v && v.length <= 50) || 'Name must be less than 50 characters',
      // (v) => (v || '').indexOf(' ') < 0 || 'No spaces are allowed',
    ],
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be formIsValid'],
    phoneNumberRules: [v => (v && v.length === 14) || 'Phone number must be 10 numbers long'],
    addressRules: [v => !!v || 'Address 1 is required', v => /^[a-zA-Z0-9\s]*$/.test(v) || 'Only letters and spaces are allowed'],
    cityRules: [
      v => !!v || 'City is required',
      v => /^[a-zA-Z][a-zA-Z\s]*$/.test(v) || 'Only letters and spaces are allowed',
      v => (v && v.length <= 50) || 'City must be less than 50 characters',
    ],
    stateRules: [
      v => !!v || 'State is required',
      v => (v && v.length == 2) || 'State must be only 2 characters',
      v => /^[A-Z]*$/.test(v) || 'Only uppercase letters are allowed',
    ],
    zipRules: [v => !!v || 'Zip Code is required'],
  }),
  computed: {
    ...mapGetters(['billingEqualToMailing']),
    billingEqualToMailing() {
      return this.$store.state.app.stepOne.billingEqualToMailing;
    },
    firstName: {
      get() {
        return this.$store.state.app.userData.firstName;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_fullName', value);
      },
    },
    lastName: {
      get() {
        return this.$store.state.app.userData.lastName;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_fullName', value);
      },
    },
    email: {
      get() {
        return this.$store.state.app.userData.email;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_email', value);
      },
    },
    phoneNumber: {
      get() {
        return this.$store.state.app.userData.phoneNumber;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_phone', value);
      },
    },
    mailingAddressOne: {
      get() {
        return this.$store.state.app.stepOne.mailingAddressOne;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_mailingAddressOne', value);
      },
    },
    mailingAddressTwo: {
      get() {
        return this.$store.state.app.stepOne.mailingAddressTwo;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_mailingAddressTwo', value);
      },
    },
    mailingCity: {
      get() {
        return this.$store.state.app.stepOne.mailingCity;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_mailingCity', value);
      },
    },
    mailingState: {
      get() {
        return this.$store.state.app.stepOne.mailingState;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_mailingState', value);
      },
    },
    mailingZipCode: {
      get() {
        return this.$store.state.app.stepOne.mailingZipCode;
      },
      set(value) {
        this.$store.dispatch('app/updateStepOne_mailingZipCode', value);
      },
    },
  },

  watch: {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      } else {
        console.log('form not valid');
        window.scrollTo(0, 0);
        this.snackMsgText = 'There are incorrect fields on the form';
        this.snackbar = true;
      }
    },
    resetChangePasswordForm() {
      this.password.current = null;
      this.password.new = null;
      this.password.newConfirm = null;
    },
  },
};
</script>

<style scoped>
.registration-body {
  margin: 0;
}
</style>
